function serializeForm(form){
    let obj = {};
    let formData = new FormData(form);
    for(let key of formData.keys()){
	obj[key] = formData.get(key);
    }
    return obj
}

function onSubmit(event, el, site){
    event.preventDefault();

    let payload = serializeForm(event.target)
    payload['site'] = site
    
    fetch('/api/subscribe', {
	method: 'POST',
	body: JSON.stringify(payload),
	headers: {
	    'Content-type':'application/json; charset=UTF-8'
	}
    }).then(function(response){
	if (response.ok){
	    return response.json();
	}
	return Promise.reject(response);
    }).then(function(data){
	// Switch Subscribed
	el.querySelector("#thankyou").style.display = "block";
	el.querySelector("#waiting").style.display = "none";
    }).catch(function(error){
	//
    });
}

export function Subscribe(el_name, site){
    let el = document.getElementById(el_name)
    el.querySelector("form").addEventListener('submit', (event) => onSubmit(event, el, site))
}

Subscribe("subscribe", "devbyexample");
